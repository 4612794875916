@use 'sass:map';
@use "layout";


$from-sm: map.get(map.get(layout.$breakpoints, sm), begin);
$to-md: map.get(map.get(layout.$breakpoints, md), end);

.shell {
  height: 100dvh;
  display: flex;
  nav.main-nav {
    display: flex;
    background-color: var(--background-toolbar);
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
      mat-icon {
        border-radius: 50%;
      }
    }
    a:hover {
      background-color: var(--background-hover);
    }
    a.active {
      color: var(--primary);
    }
    a.disabled {
      pointer-events: none;
      cursor: default;
      color: var(--disabled);
    }
  }
  main {
    flex: 1;
    overflow: auto;
    height: 100dvh;
  }
  .polaroid {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  
    img {
      opacity: 0.4;
    }
  }
}

// Desktop
@include layout.bp(gt-md) {
  .shell {
    flex-direction: row;
    nav.main-nav {
      position: sticky;
      top: 0;
      width: 200px;
      height: 100dvh;
      flex-direction: column;
      border-right: solid 1px var(--divider);
      a {
        box-sizing: border-box;
        height: 64px;
        flex-direction: row;
        padding: 0 16px;
        span {
          display: inline;
          margin-left: 16px;
        }
      }
      a.home {
        border-bottom: solid 1px var(--divider);
      }
    }
  }
}

// Tablet
@media (min-width: $from-sm) and (max-width: $to-md) {
  .shell {
    flex-direction: row;
    nav.main-nav {
      position: sticky;
      top: 0;
      width: 72px;
      height: 100dvh;
      flex-direction: column;
      border-right: solid 1px var(--divider);

      a {
        height: 72px;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        mat-icon, img {
          transform: translateY(7px);
          transition: transform 0.2s ease-in-out;
        }
        span {
          opacity: 0;
          transform: translateY(100%);
          transition: transform 0.2s ease-in-out;
          text-align: center;
          margin-left: 0; // Override global margin with
          font-size: 10px;
          line-height: 12px;
          padding-bottom: 4px;
        }
      }
      a.active {
        mat-icon, img {
          transform: translateY(0);
        }
        span {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
    .polaroid img {
      width: 80%;
    }
  }
}

@include layout.bp(sm) {
  .shell {
    .polaroid {
      display: none;
    }
  }
}

// Mobile
@include layout.bp(xs) {
  .shell {
    --main-nav-height: 56px;
    flex-direction: column-reverse;
    nav.main-nav {
      width: 100%;
      height: var(--main-nav-height);
      flex-direction: row;
      justify-content: space-around;
      border-top: solid 1px var(--divider);
      a {
        flex: 1;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        mat-icon, img {
          transform: translateY(7px);
          transition: transform 0.2s ease-in-out;
        }
        span {
          opacity: 0;
          transform: translateY(100%);
          transition: transform 0.2s ease-in-out;
          text-align: center;
          margin-left: 0; // Override global margin with
          font-size: 10px;
          line-height: 12px;
          padding-bottom: 4px;
        }
      }
      a.active {
        mat-icon, img {
          transform: translateY(0);
        }
        span {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
    .polaroid {
      display: none;
    }
  }
}
