// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@use '@angular/material' as mat;

@use "theme/components/scroll" as scroll;
@use "theme/components/list" as list;
@use "theme/components/shell";

@use "theme/light" as light;
@use "theme/dark" as dark;


@import "theme/fonts";

/////////////
// DENSITY //
/////////////
@mixin set-density($density) {
  @include mat.core-density($density);
  @include mat.progress-bar-density($density);
  @include mat.tooltip-density($density);
  @include mat.form-field-density($density);
  @include mat.input-density($density);
  @include mat.select-density($density);
  @include mat.autocomplete-density($density);
  @include mat.dialog-density($density);
  @include mat.chips-density($density);
  @include mat.slide-toggle-density($density);
  @include mat.radio-density($density);
  @include mat.slider-density($density);
  @include mat.list-density($density);
  @include mat.tabs-density($density);
  @include mat.checkbox-density($density);
  @include mat.button-density($density);
  @include mat.icon-button-density($density);
  @include mat.fab-density($density);
  @include mat.snack-bar-density($density);
  @include mat.progress-spinner-density($density);
  @include mat.badge-density($density);
  @include mat.bottom-sheet-density($density);
  @include mat.button-toggle-density($density);
  @include mat.datepicker-density($density);
  @include mat.divider-density($density);
  @include mat.expansion-density($density);
  @include mat.icon-density($density);
  @include mat.sort-density($density);
  @include mat.toolbar-density($density);
  
  // Removed
  // @include mat.card-density($density);
  // @include mat.menu-density($density);
  // @include mat.table-density($density);
  // @include mat.stepper-density($density);
  // @include mat.sidenav-density($density);
  // @include mat.paginator-density($density);
}

////////////////
// TYPOGRAPHY //
////////////////

@mixin set-typography($typography) {
  @include mat.typography-hierarchy($typography);

  @include mat.core-typography($typography);
  @include mat.progress-bar-typography($typography);
  @include mat.tooltip-typography($typography);
  @include mat.form-field-typography($typography);
  @include mat.input-typography($typography);
  @include mat.select-typography($typography);
  @include mat.autocomplete-typography($typography);
  @include mat.dialog-typography($typography);
  @include mat.chips-typography($typography);
  @include mat.slide-toggle-typography($typography);
  @include mat.radio-typography($typography);
  @include mat.slider-typography($typography);
  @include mat.list-typography($typography);
  @include mat.tabs-typography($typography);
  @include mat.checkbox-typography($typography);
  @include mat.button-typography($typography);
  @include mat.icon-button-typography($typography);
  @include mat.fab-typography($typography);
  @include mat.snack-bar-typography($typography);
  @include mat.progress-spinner-typography($typography);
  @include mat.badge-typography($typography);
  @include mat.bottom-sheet-typography($typography);
  @include mat.button-toggle-typography($typography);
  @include mat.datepicker-typography($typography);
  @include mat.divider-typography($typography);
  @include mat.expansion-typography($typography);
  @include mat.icon-typography($typography);
  @include mat.sort-typography($typography);
  @include mat.toolbar-typography($typography);
  
  @include list.typography($typography);
  
  // Removed
  // @include mat.card-typography($typography);
  // @include mat.menu-typography($typography);
  // @include mat.table-typography($typography);
  // @include mat.stepper-typography($typography);
  // @include mat.sidenav-typography($typography);
  // @include mat.paginator-typography($typography);
  
  .mat-typography {
    h1, h2, h3, h4, h5, h6, p {
      margin-block: 0;
    }
  }
}

///////////
// THEME //
///////////
@mixin set-theme($theme) {

  // MATERIAL
  @include mat.core-color($theme);
  @include mat.progress-bar-color($theme);
  @include mat.tooltip-color($theme);
  @include mat.form-field-color($theme);
  @include mat.input-color($theme);
  @include mat.select-color($theme);
  @include mat.autocomplete-color($theme);
  @include mat.dialog-color($theme);
  @include mat.chips-color($theme);
  @include mat.slide-toggle-color($theme);
  @include mat.radio-color($theme);
  @include mat.slider-color($theme);
  @include mat.list-color($theme);
  @include mat.tabs-color($theme);
  @include mat.checkbox-color($theme);
  @include mat.button-color($theme);
  @include mat.icon-button-color($theme);
  @include mat.fab-color($theme);
  @include mat.snack-bar-color($theme);
  @include mat.progress-spinner-color($theme);
  @include mat.badge-color($theme);
  @include mat.bottom-sheet-color($theme);
  @include mat.button-toggle-color($theme);
  @include mat.datepicker-color($theme);
  @include mat.divider-color($theme);
  @include mat.expansion-color($theme);
  @include mat.icon-color($theme);
  @include mat.sort-color($theme);
  @include mat.toolbar-color($theme);
  
  
  // LOCAL
  // @include mat-button.color($theme);
  // @include mat-list.color($theme);
  @include scroll.color($theme);
  
  // Removed
  // @include mat.card-color($theme);
  // @include mat.menu-color($theme);
  // @include mat.stepper-color($theme);
  // @include mat.sidenav-color($theme);
  // @include mat.paginator-color($theme);
  // @include mat.table-color($theme);


  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');
  $primary: map.get($theme, 'primary');
  $accent: map.get($theme, 'accent');
  $warn: map.get($theme, 'warn');

  // VARIABLES
  --header: rgba(white, 0.05);
  --primary: #{map.get($primary, 'default')};
  --primary-darker: #{map.get($primary, 'darker')};
  --primary-lighter: #{map.get($primary, 'lighter')};
  --primary-contrast: #{map.get($primary, 'default-contrast')};
  --accent: #{map.get($accent, 'default')};
  --accent-darker: #{map.get($accent, 'darker')};
  --accent-lighter: #{map.get($accent, 'lighter')};
  --accent-contrast: #{map.get($accent, 'default-contrast')};
  --warn: #{map.get($warn, 'default')};
  --warn-darker: #{map.get($warn, 'darker')};
  --warn-lighter: #{map.get($warn, 'lighter')};
  --warn-contrast: #{map.get($warn, 'default-contrast')};
  --warn-text: #{map.get($warn, 'text')};
  --background: #{map.get($background, 'background')};
  --background-toolbar: #{map.get($background, 'app-bar')};
  --background-hover: #{map.get($background, 'hover')};
  --surface: #{map.get($background, 'card')};
  --tooltip: #{map.get($background, 'tooltip')};
  --text: #{map.get($foreground, 'text')};
  --secondary-text: #{map.get($foreground, 'secondary-text')};
  --divider: #{map.get($foreground, 'divider')};
  --disabled: #{map.get($foreground, 'disabled-text')};
  // Specific to cropper
  --cropper-overlay-color: var(--background);

  accent-color: var(--accent);


  //////////////
  // MATERIAL //
  //////////////

  // // Form Field
  // .mat-mdc-form-field {
  //   min-width: 180px;
  //   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  //   .mat-form-field-infix {
  //     width: 100%;
  //   }
  // }
  // // Force autofill to have the background color
  // .mat-form-field-autofilled input {
  //   -webkit-box-shadow: 0 0 0px 1000px var(--background) inset;
  //   -webkit-text-fill-color: var(--text);
  // }
  // /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  // .mat-mdc-checkbox label,
  // .mat-radio label {
  //   color: inherit;
  // }
  // // Checkbox force wrap when long label
  // /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  // .mat-checkbox-layout {
  //   white-space: normal;
  // }
  // /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  // .mat-checkbox-inner-container {
  //   margin-top: 4px;
  // }

  // // Tabs
  // /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  // .mat-tab-group-inverted-header {
  //   border-top: solid 1px var(--divider);
  //   border-bottom: none;
  // }
  // /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  // .mat-tab-body-wrapper {
  //   flex-grow: 1;
  // }
  // /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  // .mat-mdc-tab-link.mat-tab-label-active {
  //   opacity: 1;
  // }

  // // Bottom sheet
  // .mat-bottom-sheet-container {
  //   padding: 0;
  // }

  // .mat-stepper-horizontal,
  // .mat-stepper-vertical {
  //   background-color: transparent;
  // }
}

////////////////
/// MATERIAL ///
////////////////
@mixin set-material() {

  // Tabs
  .mat-mdc-tab-link .mdc-tab__text-label {
    display: block; // Required to display sellers below label
  }

  // List
  .mat-mdc-subheader {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    padding: 8px 16px;
    color: var(--secondary-text);
  }
  .mdc-list-item.mdc-list-item--with-two-lines {
    .mdc-list-item__start {
      align-self: center;
      margin-block: 0;
    }
    &.mdc-list-item--with-trailing-meta {
      .mdc-list-item__end {
        display: flex;
        align-items: center;
        align-self: center;
        font-size: var(--mdc-typography-caption-font-size, 14px);
      }
    }
  }
  .mat-mdc-list-item.active {
    color: var(--primary);
  }


  // Expansion Panel
  .mat-accordion .mat-expansion-panel:last-of-type,
  .mat-accordion .mat-expansion-panel:first-of-type {
    border-radius: 0;
    box-shadow: none;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel-header {
    font-size: inherit;
    .mat-content {
      align-items: center;
    }
  }

  // Button Toggle
  .mat-button-toggle-appearance-standard {
    &.mat-button-toggle-checked {
      background-color: var(--accent);
      color: var(--accent-contrast);
    }
    .mat-button-toggle-label-content {
      line-height: 32px;
    }
  }


  // Form field
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) {
    // Remove when material is using mdc variables
    .mdc-floating-label {
      color: var(--mdc-theme-error, var(--warn-text));
    }
    .mdc-line-ripple::before, .mdc-line-ripple::after{
      border-bottom-color: var(--mdc-theme-error, var(--warn-text));
    }
  }
  .mat-mdc-form-field-infix {
    width: unset;
  }
  .mat-mdc-form-field-error {
    color: var(--mdc-theme-error, var(--warn-text));
  }
  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0 8px;
  }

  // TODO: Slide toggle
  .mat-mdc-slide-toggle {
    --mdc-switch-selected-icon-color: white;
    // --mdc-switch-selected-track-color: var(--accent-lighter);
    // --mdc-switch-selected-handle-color: var(--accent-darker);
    // --mdc-switch-selected-icon-color: var(--accent-contrast);
    // // hover
    // --mdc-switch-selected-hover-track-color: var(--accent);
    // --mdc-switch-selected-hover-handle-color: var(--accent-darker);
  }
}



@include mat.core();
@include set-density(0);

.light-theme {
  @include set-theme(light.$theme);
  // Move this into body once we remove dynamic theme
  @include set-material();
}

.dark-theme {
  @include set-theme(dark.$theme);
  @include set-material();
}




@media screen and (min-width: 500px) {
  $typography: mat.define-typography-config(
    $font-family: "Montserrat",
    $headline-5: mat.define-typography-level(24px, 32px, 400),
    /* h1 */ $headline-6: mat.define-typography-level(20px, 32px, 500),
    /* h2 */ $subtitle-1: mat.define-typography-level(16px, 28px, 400),
    /* h3 */ $body-1: mat.define-typography-level(15px, 24px, 400),
    /* h4 */ $subtitle-2: mat.define-typography-level(14px, 24px, 500),
    $body-2: mat.define-typography-level(14px, 20px, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $button: mat.define-typography-level(14px, 14px, 500),
  );
  @include set-typography($typography);

}
@media screen and (max-width: 499px) {
  $typography: mat.define-typography-config(
    $font-family: "Montserrat",
    $headline-5: mat.define-typography-level(20px, 32px, 400),
    /* h1 */ $headline-6: mat.define-typography-level(16px, 28px, 500),
    /* h2 */ $subtitle-1: mat.define-typography-level(15px, 24px, 400),
    /* h3 */ $body-1: mat.define-typography-level(14px, 24px, 400),
    /* h4 */ $subtitle-2: mat.define-typography-level(14px, 20px, 500),
    $body-2: mat.define-typography-level(12px, 20px, 400),
    $caption: mat.define-typography-level(12px, 14px, 400),
    $button: mat.define-typography-level(12px, 14px, 500),
  );
  @include set-typography($typography);

}

@media print {
  $typography: mat.define-typography-config(
    $font-family: "Montserrat",
    $headline-5: mat.define-typography-level(18pt, 24pt, 400),
    $headline-6: mat.define-typography-level(15pt, 24pt, 500),
    $subtitle-1: mat.define-typography-level(12pt, 21pt, 400),
    $body-1: mat.define-typography-level(11pt, 18pt, 400),
    $subtitle-2: mat.define-typography-level(11pt, 18pt, 500),
    $body-2: mat.define-typography-level(11pt, 15pt, 400),
    $caption: mat.define-typography-level(9pt, 15pt, 400),
    $button: mat.define-typography-level(11pt, 11pt, 500),
  );
  @include set-typography($typography);
  :root {
    --divider: rgba(0, 0, 0, 0.12);
  }
}




/* You can add global styles to this file, and also import other style files */
:root {
  color-scheme: light dark;
}

* {
  font-family: Montserrat, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  scroll-behavior: smooth;
}
market-root {
  display: block;
}

button {
  font: inherit;
  color: var(--text);
}

a:not(.mat-mdc-button-base, .mdc-tab) {
  color: var(--accent);
  &:visited {
    color: var(--primary);
  }
}

address {
  font-style: normal;
}


label,
legend {
  color: var(--secondary-text);
}

fieldset {
  border: none;
  padding: 0;
}

kbd {
  padding: 4px 8px;
  background-color: var(--background-toolbar);
  border-radius: 4px;
  border: 1px solid var(--divider);
  color: var(--secondary-text);
}

.tag {
  border-radius: 4px;
  padding: 4px 8px;
  color: var(--secondary-text);
  border: solid 1px var(--divider);
}

.icon-loading {
  animation: 1s linear spin infinite;
}

.spinner-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-sizing: border-box;
}

.surface {
  background-color: var(--surface);
  border: var(--divider);
  border-radius: 4px;
  overflow: hidden; 
  @include mat.elevation(2);
}

.mat-toolbar {
  h1 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/////////////
// DIALOGS //
/////////////
dialog {
  background-color: var(--background);
  border-radius: 4px;
  border: none;
} 

// desktop
@media (min-width: 600px) {
  .dialog-full-panel {
    max-width: 500px !important; // required because mat-dialog apply 80vw inline-style by default
    max-height: 70vh;
  }
}
// Mobile
@media (max-width: 599px) {
  .dialog-full-panel {
    max-width: 90vw !important; // required because mat-dialog apply 80vw inline-style by default
    max-height: 90dvh;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale(0.95) translateY(10px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

// LIST
table.record, table.list {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  outline: 1px solid var(--divider); // Use outline instead of border to get the radius
  th {
    background-color: var(--background-toolbar);
    color: var(--text);
    font-weight: 300;
  }
  tr:not(:last-of-type) {
    border-bottom: 1px solid var(--divider);
  }
}
table.record {
  th {
    border-right: 1px solid var(--divider);
    text-align: left;
    white-space: nowrap;
  }
}
table.list {
  thead tr {
    border-bottom: 1px solid var(--divider);
  }
  td .clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }
}
