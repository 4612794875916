
@use 'sass:map';
@use '@angular/material' as mat;
@use "layout";

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, 'foreground');
  $contrast: mat.get-color-from-palette($foreground, text);

  // Use native scroll for smartphone
  @include layout.bp(gt-sm) {
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      width: 5px;
      background: rgba($contrast, 0.1);
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      border-radius: 9px;
      background: rgba($contrast, 0.5);
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba($contrast, 0.8);
    }
  }
}
