@use '@angular/material' as mat;

ul.list, ul.nav-list {
  padding: 0;
  list-style: none;

  img {
    border-radius: 20px;
    height: 40px;
    width: 40px;
    margin-right: 16px;
  }

  .list-text {
    flex: 1;
    min-width: 0;
  }
}

ul.list {
  li {
    display: flex;
    align-items: center;
    padding: 8px 16px;
  }
}

ul.nav-list {
  a {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    padding: 8px 16px;
  }

  a:hover {
    background-color: var(--background-hover);
  }
}


@mixin typography($typography) {
  .list-text p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:first-child {
      @include mat.typography-level($typography, 'body-1');
    }
    &:not(:first-child) {
      @include mat.typography-level($typography, 'caption');
    }
  }
}